<template>
  <section class="app-container">
    <Filter @search="handleSearch" @refresh-table="handleResetTable" />
    <SkillList ref="skillListRef"></SkillList>
  </section>
</template>
<script>
import { defineComponent, ref } from "vue";
import Filter from "./components/Filter.vue";
import SkillList from "./components/List";


export default defineComponent({
  name: "orientationGuidelines",
  components: {
    SkillList,
    Filter,
  },
  setup() {
    const skillListRef = ref();
    const handleSearch = (searchParams) => {
      skillListRef.value.searchClick(searchParams);
    };
    const handleResetTable = () => {
      skillListRef.value.doSearch();
    };
    return {
      skillListRef,
      handleSearch,
      handleResetTable,
    };
  },
});
</script>
<style scoped lang="less">
.app-container {
  height: 100%;
  padding: 10px 20px;
  background: #ffffff;
  border-radius: 8px;
}
</style>
  