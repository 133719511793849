<template>
  <section class="org-list">
    <a-table ref="skillListRef" :columns="columns" :data-source="skillList" rowKey="idNumber" :scroll="{
      x:1100,
      y: tableHeight
    }" :pagination="pagination">
    <template #fileName="{ record }">
      <span>{{ record.fileName }}</span>
    </template>
      <template #action="{ record }">
<!--
        <a-space>
          <span v-auth="['system:orientationguidelines:upload']" class="operationLink underline"
            @click="uploadClick(record)">上传</span>
          <span v-auth="['system:orientationguidelines:download']"
            :class="`${record.fileId ? 'operationLink underline' : 'operationLink underline disabled'}`"
            @click="downloadClick(record)">下载</span>
          <span v-auth="['system:orientationguidelines:delete']"
            :class="`${record.fileId ? 'redLink underline' : 'redLink underline disabled'}`"
            @click="deleteClick(record)">删除</span>
        </a-space>-->
        <ActionBtn v-bind="record" :btnList="btnList" @UploadOutlined="uploadClick(record)" @DownloadOutlined="downloadClick(record)" @DeleteOutlined="deleteClick(record)" />
      </template>
    </a-table>
  </section>
  <import-modal v-model:visible="modalVisible" apiFuncName="uploadOrganizations" :uploadParams="{templateId:templateId}"
    source="OrientationGuidelines" accept=".doc,.docx,.ppt,.pptx,.xls,.xlsx,.txt,.pdf,.7z,.zip,.rar"
    @closeModal="handleCloseModal" @resetTable="handleResetTable">
  </import-modal>

  <ModalBox v-model="modelValue" :ok="handleOk" :content="content" :showIconCom="showIconCom" />
</template>
<script>
import {
  defineComponent,
  nextTick,
  onMounted,
  onUnmounted,
  reactive,
  ref,
} from "vue";
import { useGlobalPropertyHook } from "@/hooks/common.js";
import { useTableHeight } from "@/utils/common";
import ImportModal from "@/components/ImportModal.vue";
import { downloadFile } from "@/utils/common";
import { message } from 'ant-design-vue';
import ModalBox from '@/components/ModalBox'
import ActionBtn from '@/components/ActionBtn'

const btnList = [
  {
    name: '上传',
    icon: 'UploadOutlined',
    auth: ['system:orientationguidelines:upload']
  },
  {
    name: '下载',
    icon: 'DownloadOutlined',
    auth: ['system:orientationguidelines:upload']
  },
  {
    name: '删除',
    icon: 'DeleteOutlined',
    auth: ['system:orientationguidelines:delete']
  },
]
export default defineComponent({
  name: "CostCtrList",
  components: { ImportModal, ModalBox, ActionBtn },
  setup() {
    const { $api } = useGlobalPropertyHook();
    const skillListRef = ref();
    const modalVisible = ref(false)
    const templateId = ref('')
    const columns = [
      {
        title: "Team",
        dataIndex: "orgThreeTwo",
        key: "orgThreeTwo",
        ellipsis: true,
        width: 150,
      },
      {
        title: "Offering",
        dataIndex: "orgFourOneOne",
        key: "orgFourOneOne",
        ellipsis: true,
        width: 200,
      },
      {
        title: "Org5.1",
        dataIndex: "orgFiveOne",
        key: "orgFiveOne",
        ellipsis: true,
        width: 150,
      },
      {
        title: "入职指引",
        dataIndex: "action",
        width: 300,
        ellipsis: true,
        slots: { customRender: "fileName" },
      },
      {
        title: "Operation Time",
        dataIndex: "operationTime",
        key: "operationTime",
        ellipsis: true,
        width: 160,
      },
      {
        title: "Operator",
        dataIndex: "operator",
        key: "operator",
        ellipsis: true,
        width: 150,
      },
      {
        title: "操作",
        dataIndex: "action",
        width: localStorage.getItem("switchRoleName")==='RM' ? 120 : 100,
        ellipsis: true,
        slots: { customRender: "action" },
      },
    ];
    const pagination = reactive({
      current: 1,
      pageSize: 12,
      pageSizeOptions: ['12','20','30','40'],
      total: 0,
      showSizeChanger: true,
      showTotal: (total) => {
        return `共 ${total} 条`;
      },
      onChange: (page) => {
        pagination.current = page;
        doSearch(searchCondition);
      },
      onShowSizeChange: (_, size) => {
        pagination.pageSize = size;
        pagination.current = 1;
        doSearch(searchCondition);
      },
    });
    const skillList = ref();
    let searchCondition = {};

    const doSearch = (searchParams = searchCondition) => {
      const params = {
        pageIndex: pagination.current,
        pageSize: pagination.pageSize,
        ...searchParams,
      };
      $api.queryOrgInfoByCondition(params).then((res) => {
        pagination.total = res.data.total;
        skillList.value = res.data.resource;
      });
    };

    const searchClick = (searchParams) => {
      pagination.current = 1;
      searchCondition = searchParams;
      doSearch(searchCondition);
    };
    const handleCloseModal = () => {
      modalVisible.value = false
    }
    const tableHeight = ref(0);

    const getSize = () => {
      nextTick(() => {
        tableHeight.value = useTableHeight();
      });
    };

    window.addEventListener("resize", getSize);

    onMounted(() => {
      getSize();
    });

    onUnmounted(() => {
      window.removeEventListener("resize", getSize);
    });
    const uploadClick = (record) => {
      templateId.value = record.orgFourOneOne
      modalVisible.value = true

    }
    const handleResetTable = () => {
      doSearch(searchCondition);
    }

    const downloadClick = (record) => {
      $api.onboardGuideDownloadFile(record.fileId).then((res) => {
        downloadFile(res.file, record.fileName)
      })
    }

    const modelValue = ref(false)
    const content = ref('')
    const showIconCom = ref('')
    const modalData = ref()
    const handleOk = () => {
      const {record} = modalData.value
      $api.organizationsDeleteFile(record.fileId, record.orgFourOneOne).then((res) => {
        if (res.msg == '当前有人员引用了该入职指引，不可删除') {
          message.error('当前有人员引用了该入职指引，不可删除！');
        } else {
          doSearch(searchCondition);
          message.success('删除成功！');
        }
      })
    }
    const deleteClick = (record) => {
      content.value = `是否确定删除${record.orgFourOneOne}对应的入职指引附件`
      modalData.value = {record}
      showIconCom.value = 'ExclamationCircleOutlined'
      modelValue.value = true
      /*Modal.confirm({
        title: `是否确定删除${record.orgFourOneOne}对应的入职指引附件`,
        icon: createVNode(ExclamationCircleOutlined),
        okText: '确定',
        width: "30%",
        centered: true,
        onOk() {
          $api.organizationsDeleteFile(record.fileId, record.orgFourOneOne).then((res) => {
            if (res.msg == '当前有人员引用了该入职指引，不可删除') {
              message.error('当前有人员引用了该入职指引，不可删除！');
            } else {
              doSearch(searchCondition);
              message.success('删除成功！');
            }

          })
        },
        cancelText: '取消',
      });*/
    }

    return {
      skillListRef,
      columns,
      pagination,
      skillList,
      searchClick,
      tableHeight,
      doSearch,
      downloadClick,
      uploadClick,
      deleteClick,
      modalVisible,
      handleCloseModal,
      handleResetTable,
      templateId,
      modelValue,
      content,
      showIconCom,
      handleOk,
      btnList
    };
  },
});
</script>
<style scoped lang="less">
// 覆盖ant-design样式
::v-deep .ant-table-body {
  overflow: auto;
}

:deep(.ant-table-thead) {
  >tr>th {
    white-space: nowrap;
  }
}

:deep(.ant-table-tbody) {
  >tr>td {
    white-space: nowrap;
  }
}

.org-list {
  display: flex;
  width: 100%;
  max-height: calc(100% - 88px);

  .ant-table-wrapper {
    flex: 1;
    width: 0;
  }

  .org-info {
    width: 300px;
    //margin-left: 20px;
    background: #f4f4f4;
  }
}
</style>
