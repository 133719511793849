<template>
  <div ref="staffingExportModal">
    <a-modal
      :getContainer="() => $refs.staffingExportModal"
      title="导入"
      width="29.2vw"
      :visible="visible"
      :footer="null"
      @cancel="closeModal"
      :z-index="10001"
      centered
    >
      <slot></slot>
      <a-upload-dragger
        name="file"
        v-model:fileList="fileList"
        :multiple="false"
        :accept="accept"
        :showUploadList="false"
        :beforeUpload="handleBeforeUpload"
        :customRequest="handleCustomRequest"
      >
        <p class="ant-upload-drag-icon">
          <UploadOutlined />
        </p>
        <p class="ant-upload-text">单击或拖动文件到此区域以上传</p>
        <p class="ant-upload-hint">
          支持单个上传。严禁上传公司数据或其他信息安全文件
        </p>
      </a-upload-dragger>
    </a-modal>
  </div>
  <notice-modal
    :modalVisible="modalVisible"
    modalWidth="20.8vw"
    cancelBtn="取消"
    @close-modal="closeMessageModal"
    title="导入失败"
  >
    <ExclamationCircleOutlined class="icon-position" />
    <div v-for="item in errorMessage" :key="item.id">{{ item }}</div>
  </notice-modal>
</template>
<script>
import { defineComponent, ref } from "vue";
import { message } from "ant-design-vue";
import { useGlobalPropertyHook } from "@/hooks/common";
import NoticeModal from "@/components/NoticeModal.vue";
import { UploadOutlined,ExclamationCircleOutlined } from "@ant-design/icons-vue";

export default defineComponent({
  components: { NoticeModal, UploadOutlined,ExclamationCircleOutlined },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    apiFuncName: {
      type: String,
      required: true,
    },
    uploadParams: {
      type: Object,
      default: () => { },
    },
    accept: {
      type: String,
      default: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, .xltm, .xlsm"
    },
    isCompressionType: {
      type: Boolean,
      default: false,
    },
    source: {
      type: String,
      default: ''
    },
  },
  emits: [
    "closeModal",
    "resetTable",
    "updateMessage",
    "uploadStart",
    "updateError",
    "insertModal",
  ],
  setup(props, ctx) {
    const { $api } = useGlobalPropertyHook();
    const formData = new FormData();
    const fileList = ref([]);
    const errorMessage = ref([]);
    const modalVisible = ref(false);

    const closeModal = () => {
      ctx.emit("closeModal");
    };

    const handleBeforeUpload = (file) => {
      //h合并冲突,改为if(props.source === 'OrientationGuidelines') /else-if(props.isCompressionType === false) /else格式
      if (props.source === 'OrientationGuidelines') {
        const isLt50M = file.size / 1024 / 1024 < 50;
        if (!isLt50M) {
          message.error("文件大小限制50MB，请检查后再次上传!");
          return false;
        }
        if (!file.type.includes("application/msword") &&
          !file.type.includes("application/vnd.openxmlformats-officedocument.wordprocessingml.document") &&
          !file.type.includes("application/vnd.ms-powerpoint") &&
          !file.type.includes("application/vnd.openxmlformats-officedocument.presentationml.presentation") &&
          !file.type.includes("application/vnd.ms-excel") &&
          !file.type.includes("application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") &&
          !file.type.includes("text/plain") &&
          !file.type.includes("application/pdf") &&
          !(file.name.slice(-3) === '.7z') &&
          !file.type.includes("application/x-zip-compressed") &&
          !(file.name.slice(-4) === '.rar')
        ) {
          message.error("只可以上传doc,docx,ppt,pptx,xls,xlsx,txt,pdf,7z,zip,rar格式的文件！");
          return false;
        }
      } else if (props.isCompressionType === false){
        if (!file.type.includes("application/vnd.ms-excel") &&
          !file.type.includes("application/vnd.openxmlformats-officedocument.spreadsheetml.sheet")) {
          fileList.value = [];
          message.error("只可以上传Excel文件！");
          return false
        }
      } else {
        if (!file.type.includes("application/x-zip-compressed")&&!(file.name.slice(-3)==='.7z')&&!(file.name.slice(-4)==='.rar')) {
          fileList.value = [];
          message.error("只可以上传7z,zip,rar文件！");
          return false;
        }
      }
      if (formData.has("file")) {
        formData.delete("file");
      }
      formData.append("file", file);
    };

    const handleCustomRequest = () => {
      ctx.emit("closeModal");
      ctx.emit("uploadStart");
      $api[props.apiFuncName](formData, props.uploadParams)
        .then((res) => {
          const code = res.code;
          const msg = res.msg.replaceAll("<br/>", " ");
          fileList.value = [];

          if (res.data?.length) {
            errorMessage.value = res.data;
            modalVisible.value = true;
            ctx.emit("updateError");
            return;
          }
          if (code === 200) {
            if (res.data === 1) {
              ctx.emit("insertModal");
            } else {
              message.success(msg);
              ctx.emit("resetTable");
              ctx.emit("updateMessage");
            }
          } else {
            message.error(msg);
            ctx.emit("updateError");
            console.log(msg);
          }
        })
        .catch(() => {
          ctx.emit("updateError");
        });
    };

    const closeMessageModal = () => {
      modalVisible.value = false;
    };

    return {
      fileList,
      handleBeforeUpload,
      closeModal,
      handleCustomRequest,
      errorMessage,
      modalVisible,
      closeMessageModal,
    };
  },
});
</script>
<style lang="less" scoped>
@import "~@/style/aModal.less";
:deep(.content){
  position: relative;
  padding-left: 26px;
  font-size: 16px;
  line-height: 22px;
}
.icon-position{
  position: absolute;
  top: 3px;
  left: 4px;
  color: #DC5E4F;

}
</style>
