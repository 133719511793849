<template>
  <section class="top-filter">
    <div class="mb12">
      <FilterLayout @doReset="resetForm"
                    @doSearch="doSearch"
                    ref="filterLayoutRef">
        <template #customform>
          <a-form
              ref="formRef"
              class="form rms-form box"
              layout="vertical"
              :model="formState"
          >
            <a-form-item
                label="Org5.1"
                name="orgFiveOneList"
                class="rms-form-item"
            >
              <SelectWithAll
                  v-model:value="formState.orgFiveOneList"
                  placeholder="请选择"
                  mode="multiple"
                  showArrow
                  allowClear
                  :maxTagCount="1"
                  :options="orgOpsObj.orgFiveOneOps"
                  @change="orgThreeTwoChange('orgFiveOne')"
              >
              </SelectWithAll>
            </a-form-item>
            <a-form-item label="Team" name="orgThreeTwoList" class="rms-form-item">
              <SelectWithAll
                  v-model:value="formState.orgThreeTwoList"
                  placeholder="请选择"
                  mode="multiple"
                  showArrow
                  allowClear
                  :maxTagCount="1"
                  :options="orgOpsObj.orgThreeTwoOps"
                  @change="orgThreeTwoChange('orgThreeTwo')"
              >
              </SelectWithAll>
            </a-form-item>
            <a-form-item label="Offering" name="orgFourOneOneList" class="rms-form-item">
              <SelectWithAll
                  v-model:value="formState.orgFourOneOneList"
                  placeholder="请选择"
                  mode="multiple"
                  showArrow
                  allowClear
                  :maxTagCount="1"
                  :options="orgOpsObj.orgFourOneOneOps"
                  @change="orgThreeTwoChange('orgFourOneOne')"
              >
              </SelectWithAll>
            </a-form-item>
          </a-form>
        </template>
      </FilterLayout>
    </div>
  </section>
</template>
<script>
import { defineComponent, reactive, ref, onMounted, onActivated } from "vue";
import { useGlobalPropertyHook } from "@/hooks/common";
import {getValueAndOps} from "@/utils/orgSelectData";
import {dictionaryOpt} from '@/utils/common'

export default defineComponent({
  name: "TopFilter",
  components: {

  },
  emits: ["search", "export-click", "refresh-table"],
  setup(props, ctx) {
    const { $api } = useGlobalPropertyHook();
    const formRef = ref();
    let currentParams = {}
    //只有在点击查询按钮时才更新筛选项
    const updateParams =() => {
      currentParams = {
        orgThreeTwoList: formState.orgThreeTwoList,
        orgFourOneOneList: formState.orgFourOneOneList,
        orgFiveOneList: formState.orgFiveOneList,
      }
    }

    const orgFiveOneOps = ref([]);
    const orgThreeTwoOps = ref([]);
    const orgFourOneOneOps = ref([]);
    const formState = reactive({
      orgThreeTwoList: [],
      orgFourOneOneList: [],
      orgFiveOneList: [],
    });
    const orgOpsObj = reactive({
      orgFiveOneOps: [],
      orgThreeTwoOps: [],
      orgFourOneOneOps: [],
    });
    const resetForm = () => {
      Object.assign(formState , {
        orgFiveOneList: [],
        orgThreeTwoList: [],
        orgFourOneOneList: [],
      })
      updateParams()
      getOrgList()
      ctx.emit("search", currentParams);
    };
    const getOrgList = () => {
      $api.getOrgTwo_Three_Four_FiveList().then((res) => {
        const { orgFourOneOneList, orgFiveOneList, orgThreeTwoList } = res.data;
        orgOpsObj.orgFiveOneOps = dictionaryOpt(orgFiveOneList);
        orgOpsObj.orgThreeTwoOps = dictionaryOpt(orgThreeTwoList);
        orgOpsObj.orgFourOneOneOps = dictionaryOpt(orgFourOneOneList);
      });
    };
    const orgAllList = ref([]);
    const getOrgRelatedList = () => {
      $api.getAllOrgRelatedList().then((res) => {
        orgAllList.value = res.data;
      });
    };
    const orgThreeTwoChange = (type) => {
      const formOrgData = {
        orgFiveOneList: formState.orgFiveOneList,
        orgThreeTwoList: formState.orgThreeTwoList,
        orgFourOneOneList: formState.orgFourOneOneList,
      };
      const { orgsValue, orgOpsList } = getValueAndOps(
          orgAllList.value,
          formOrgData,
          orgOpsObj,
          type
      );
      Object.assign(formState, orgsValue);
      Object.assign(orgOpsObj, orgOpsList);
    };
    const doSearch = () => {
      updateParams()
      ctx.emit("search", currentParams);
    };
    const show = ref(true);
    onMounted(() => {
      // nextTick(doSearch);
      getOrgList();
      getOrgRelatedList()
    });
    onActivated(() => {
      ctx.emit("refresh-table");
    });

    return {
      formState,
      formRef,
      updateParams,
      doSearch,
      resetForm,
      orgThreeTwoOps,
      orgFourOneOneOps,
      orgThreeTwoChange,
      show,
      orgFiveOneOps,
      orgOpsObj,
      orgAllList,
      getOrgRelatedList,
    };
  },
});
</script>
<style scoped lang="less">
.top-filter {
  width: 100%;
  .feature-line{
    text-align: right;
    margin-top:-20px;
    margin-bottom: -5px;
  }
}
</style>
