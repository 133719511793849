<template>
  <div ref="modalRef">
    <a-modal
      :visible="modalVisible"
      :title="title"
      :closable="closable"
      :getContainer="() => $refs.modalRef"
      :width="modalWidth"
      @cancel="cancel"
      centered
    >
      <template #footer>
        <a-button type="primary" @click="handleOk" :style="{display: sumbitBtn !== '' ? 'inherits' : 'none'}">
          {{ sumbitBtn }}
        </a-button>
        <a-button @click="cancel" :style="{
          display: cancelBtn !== '' ? 'inherits' : 'none',
          marginLeft: sumbitBtn === '' ? 0 : 'inherits'}">
          {{ cancelBtn }}
        </a-button>
      </template>
      <div class="content">
        <slot></slot>
      </div>
    </a-modal>
  </div>
</template>
<script>
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    sumbitBtn: {
      type: String,
      default: '',
    },
    cancelBtn:{
      type: String,
      default: '',
    },
    content: String,
    title: String,
    modalVisible: Boolean,
    closable: {
      type: Boolean,
      default: false,
    },
    modalWidth: {
      type: Number || String,
      default: 300,
    },
  },
  emits: ["sumbit-modal", "close-modal"],
  setup(_, { emit }) {
    const handleOk = () => {
      emit("sumbit-modal");
    };
    const cancel = () => {
      emit("close-modal");
    };
    return {
      handleOk,
      cancel,
    };
  },
});
</script>
<style lang="less" scoped>
@import "~@/style/aModal.less";
:deep(.ant-modal-body) {
  max-height: 258px;
  overflow: auto;
  &::-webkit-scrollbar {
    width: 8px;
    height: 13px;
  }

  &::-webkit-scrollbar-thumb {
    width: 8px;
    height: 13px;
    background: #d0e8ff;
    border: 1px solid #d0e8ff;
    border-radius: 5px;
  }
}
:deep(.ant-modal-header) {
  height: 55px;
}
</style>
>
